import { StrictMode, Suspense } from "react";
import { QueryClientProvider } from "react-query";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { run } from "utils";
import { IdentityType } from "auth/AuthContext";
import { AuthProvider } from "auth/AuthProvider";
import { useClearUserDataOnLogout } from "auth/useClearUserDataOnLogout";
import { AuthenticatedRoute } from "components/Routes/AuthenticatedRoute";
import { UnauthenticatedRoute } from "components/Routes/UnauthenticatedRoute";
import { Spinner } from "components/Spinner/Spinner";
import { AppVersionProvider } from "contexts/AppVersion/AppVersionProvider";
import { UserProvider } from "contexts/User/UserProvider";
import { GraphQLClientProvider } from "graphql-client/GraphQLClientProvider";
import { routes } from "routes";
import {
  AppListener,
  ErrorBoundary,
  NotificationsContainer,
  OutdatedBrowserBanner,
  OutdatedVersionBanner,
  SubscriptionReadyMarker,
  usePageViews,
} from "singletons";
import { queryClient } from "utils/query";

import { IapRedirect } from "singletons/IapRedirect";
import { QueuedNotificationsListener } from "singletons/QueuedNotificationsListener";
import { AutomaticIdentitySwitcher } from "auth/singletons/AutomaticIdentitySwitcher";
import { LegacyUrlRedirect } from "singletons/LegacyUrlRedirect";
import { ForgotPassword } from "./auth/views/ForgotPassword";
import { PaletteApp } from "./views/PaletteApp/PaletteApp";
import { PlaygroundApp } from "./views/PlaygroundApp/PlaygroundApp";
import { CopilotApiSignup } from "./auth/views/Signup";
import { DoctorApp } from "./views/DoctorApp/DoctorApp";
import { Login } from "./auth/views/Login";
import { ChooseCredentials } from "./auth/views/ChooseCredentials";
import { AfterGoogleLogin } from "./auth/views/AfterGoogleLogin";
import { FullPageIdentityPicker } from "./auth/views/FullPageIdentityPicker";
import { FullPageIdentityPickerConfirmation } from "./auth/views/FullPageIdentityPickerConfirmation";
import { isPaletteEnabled } from "utils/environment";
import { PublicRoute } from "./components/Routes/PublicRoute";

// organize-imports-ignore
// This import must be the last one so tailwind classes can override any module.css
import "./styles/index.css";
import "./i18n";
import { Redirect } from "./components/Routes/Redirect";
import { IdentityPickerState } from "./components/IdentityPicker/utils";
import { AccountLoginLink } from "./auth/views/AccountLoginLink";
import { CopilotApiApp } from "./views/CopilotApiApp/CopilotApiApp";
import { ScheduledOrganizationCreator } from "./auth/singletons/ScheduledOrganizationCreator";
import { CompanySubscriptionErrorPage } from "./auth/views/CompanySubscriptionErrorPage";
import { CopilotSubscriptionSucceeded } from "./auth/views/CopilotSubscriptionSucceeded";

const AuthSwitch = () => {
  const { pathname } = useLocation();
  useClearUserDataOnLogout();
  usePageViews();

  return pathname.startsWith(routes.PALETTE) && isPaletteEnabled ? (
    <PaletteApp />
  ) : pathname.startsWith(routes.PLAYGROUND) ? (
    <PlaygroundApp />
  ) : (
    <>
      <SubscriptionReadyMarker />
      <OutdatedBrowserBanner />
      <OutdatedVersionBanner />
      <Routes>
        <PublicRoute
          path={`${routes.CHOOSE_CREDENTIALS}*`}
          element={<ChooseCredentials />}
        />
        <PublicRoute
          path={`${routes.GOOGLE_LOGIN}*`}
          element={<AfterGoogleLogin />}
        />
        <PublicRoute
          path={`${routes.FORGOT_PASSWORD}*`}
          element={<ForgotPassword />}
        />
        <PublicRoute
          path={`${routes.ACCOUNT_LOGIN_LINK}*`}
          element={<AccountLoginLink />}
        />
        <PublicRoute
          path={`${routes.COPILOT_API_SIGNUP}`}
          element={<CopilotApiSignup />}
        />
        <PublicRoute
          path={routes.COPILOT_COMPANY_SUBSCRIPTION_ERROR}
          element={<CompanySubscriptionErrorPage />}
        />
        <PublicRoute
          path={routes.COPILOT_SUBSCRIPTION_SUCCEEDED}
          element={<CopilotSubscriptionSucceeded />}
        />

        <UnauthenticatedRoute path={`${routes.LOGIN}*`} element={<Login />} />
        <AuthenticatedRoute
          path="*"
          element={(identityType) => (
            <AuthenticatedApp identityType={identityType} />
          )}
        />
      </Routes>
    </>
  );
};

const AuthenticatedApp = ({
  identityType,
}: {
  identityType: IdentityType | undefined;
}) => (
  <ScheduledOrganizationCreator>
    <UserProvider type={identityType}>
      <AutomaticIdentitySwitcher>
        <Routes>
          <Route
            path={`${routes.PICK_IDENTITY}/:uuid`}
            element={<FullPageIdentityPickerConfirmation />}
          />
          <Route
            path={routes.PICK_IDENTITY}
            element={<FullPageIdentityPicker />}
          />
          <Route
            path="*"
            element={run(() => {
              switch (identityType) {
                case undefined:
                  return (
                    <Redirect<IdentityPickerState>
                      to={routes.PICK_IDENTITY}
                      state={{ shouldAutomaticallyPickSingleIdentity: true }}
                    />
                  );
                case "COPILOT_API_DEVELOPER":
                  return <CopilotApiApp />;
                case "DOCTOR":
                  return <DoctorApp />;
              }
            })}
          />
        </Routes>
      </AutomaticIdentitySwitcher>
    </UserProvider>
  </ScheduledOrganizationCreator>
);

export const App = () => (
  <StrictMode>
    <ErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <LegacyUrlRedirect>
          <QueryClientProvider client={queryClient}>
            <Router>
              <IapRedirect />
              <AppListener />
              <NotificationsContainer />
              <QueuedNotificationsListener />
              <AppVersionProvider>
                <AuthProvider>
                  <GraphQLClientProvider>
                    <AuthSwitch />
                  </GraphQLClientProvider>
                </AuthProvider>
              </AppVersionProvider>
            </Router>
          </QueryClientProvider>
        </LegacyUrlRedirect>
      </Suspense>
    </ErrorBoundary>
  </StrictMode>
);
