import {
  CurrentCopilotApiDeveloperData,
  PermissionKnownValue as ApiDeveloperPermissionKnownValue,
} from "generated/copilot-api-developer";
import {
  CurrentDoctorData,
  DoctorRoleKnownValue,
  GatekeeperFeatureId,
  PermissionKnownValue as DoctorPermissionKnownValue,
} from "generated/provider";
import { IconName } from "icon-library";
import { optionalIntl } from "utils/intl";

import { WithOptionalFields } from "./utils";

export type AccessProps =
  | DoctorAccessProps
  | CopilotApiDeveloperAccessProps
  | SuperuserAccessProps;

type DoctorAccessProps = AccessPropsBase & {
  type: "DOCTOR";
  user: CurrentDoctorData["doctor"];
};

type CopilotApiDeveloperAccessProps = AccessPropsBase & {
  type: "COPILOT_API_DEVELOPER";
  user: CurrentCopilotApiDeveloperData["developer"];
};

type SuperuserAccessProps = AccessPropsBase & {
  type: "SUPERUSER";
  user: null;
};

type AccessPropsBase = {
  hasPermission: (
    permission: DoctorPermissionKnownValue | ApiDeveloperPermissionKnownValue,
  ) => boolean;
  hasRole: (role: DoctorRoleKnownValue) => boolean;
  hasAccessToOrganizationUserApi: boolean;
  hasAccessToGatekeeperFeature: (feature: GatekeeperFeatureId) => boolean;
  isDesktop: boolean;
};

export type MainSidebarItem = Omit<ThinSidebarItem, "useCount"> & {
  hasAccess: (
    props: Omit<AccessProps, "hasAccessToOrganizationUserApi">,
  ) => boolean;
  useCount?: (
    props?: Omit<AccessProps, "hasAccessToOrganizationUserApi">,
  ) => number | undefined;
  organizationUserOnly?: boolean;
  hidden?: boolean;
};

export type AccountMenuItem = WithOptionalFields<MainSidebarItem, "hasAccess">;

export type SidebarItem = WithOptionalFields<
  MainSidebarItem,
  "hasAccess" | "icon"
> & { mobileStickOnSidebar?: boolean };

export type RouteProps = Pick<
  SidebarItem,
  "to" | "Component" | "hasAccess" | "organizationUserOnly"
>;

export type SidebarLabel =
  | string
  | [string, string, string]
  | ((props: AccessProps) => string | [string, string, string]);

export type ThinSidebarItem = {
  to: string;
  icon: IconName;
  label: SidebarLabel;
  Component: () => JSX.Element;
  useCount?: () => number | undefined;
  maxCount?: number;
};

export const filterAvailableSidebarItems = <T extends RouteProps>(
  items: T[],
  props: AccessProps,
): T[] =>
  items.filter(
    (item) =>
      (!item.organizationUserOnly || props.hasAccessToOrganizationUserApi) &&
      (!item.hasAccess || item.hasAccess(props)),
  );

export const resolveSidebarLabel = (
  label: SidebarLabel,
  accessProps: AccessProps,
): string =>
  typeof label === "string"
    ? label
    : Array.isArray(label)
    ? optionalIntl(label) ?? ""
    : optionalIntl(label(accessProps)) ?? "";
