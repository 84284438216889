import { ReactElement, useEffect, useState } from "react";
import gql from "graphql-tag";

import { useLoggedInAuth } from "auth/AuthContext";
import {
  isVerifiedLoginMethod,
  VerifiedLoginMethodKnownValue,
} from "auth/utils";
import {
  deriveProbablyUniqueOrganizationStringId,
  executeScheduledOrganizationCreation,
} from "auth/utils/signup";
import { IdentitiesQuery } from "components/IdentityPicker/IdentitiesQuery";
import {
  getGqlLoginMethod,
  getIdentityFromGql,
  isPickableIdentity,
  KnownIdentityFragment,
  usePickIdentity,
} from "components/IdentityPicker/utils";
import { Spinner } from "components/Spinner/Spinner";
import { CreateCopilotApiOrganization } from "generated/account";
import { useMutation } from "graphql-client/useMutation";
import { run } from "utils";
import { notifier } from "utils/notifier";

gql`
  # schema = ACCOUNT
  mutation CreateCopilotApiOrganization(
    $organizationInput: CopilotApiOrganizationInput!
    $initialIdentityInput: InitialCopilotApiDeveloperIdentityInput!
  ) {
    createCopilotApiOrganization(
      organization: $organizationInput
      initialIdentity: $initialIdentityInput
    ) {
      organization {
        uuid
      }

      initialIdentity {
        uuid
        acceptableLoginMethods
        developer {
          ...CopilotApiDeveloperSummary
        }
      }
    }
  }
`;

// Creates all organizations that were previously queued for creation via calls
// to the `scheduleOrganizationCreation` helper if the user is logged with an
// account token, and does nothing otherwise. Also sets the user's current
// identity to the initial identity of the created organization.
export const ScheduledOrganizationCreator = ({
  children,
}: {
  children: ReactElement;
}) => {
  const auth = useLoggedInAuth();
  const currentLoginMethod = getGqlLoginMethod(auth.currentSessionLoginMethod);
  return auth.canChangeIdentity && isVerifiedLoginMethod(currentLoginMethod) ? (
    <IdentitiesQuery>
      {({ accountUnverifiedEmail }) => (
        <ScheduledOrganizationCreatorWhenLoggedViaAccount
          accountEmail={accountUnverifiedEmail}
          accountLoginMethod={currentLoginMethod}
          children={children}
        />
      )}
    </IdentitiesQuery>
  ) : (
    children
  );
};

const ScheduledOrganizationCreatorWhenLoggedViaAccount = ({
  accountEmail,
  accountLoginMethod,
  children,
}: {
  accountEmail: string;
  accountLoginMethod: VerifiedLoginMethodKnownValue;
  children: ReactElement;
}) => {
  const pickIdentity = usePickIdentity();
  const [isLoading, setIsLoading] = useState(true);

  // @graphql-schema-usage-ignore: Currently logged in via account.
  const [createCopilotApiOrganization] = useMutation(
    CreateCopilotApiOrganization,
    {
      throwOnError: true,
    },
  );

  useEffect(() => {
    setIsLoading(true);

    executeScheduledOrganizationCreation(accountEmail, async (payload) => {
      const identityFragment: KnownIdentityFragment = await run(async () => {
        switch (payload.type) {
          case "COPILOT_API_ORGANIZATION": {
            const organization = payload.organization;
            const data = await createCopilotApiOrganization({
              organizationInput: {
                id: deriveProbablyUniqueOrganizationStringId(
                  organization.displayName,
                ),
                ...organization,
              },
              initialIdentityInput: {},
            });
            return data.initialIdentity;
          }
        }
      });

      const identity = getIdentityFromGql(
        { identity: identityFragment },
        accountLoginMethod,
      );

      if (isPickableIdentity(identity)) {
        pickIdentity(identity, { hardNavigate: true });
      } else {
        pickIdentity(null);

        // Silently error out.
        notifier.error({
          sentry: {
            message:
              "Received unpickable identity after calling CreateOrganization.",
            level: "error",
          },
        });
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }, [
    accountEmail,
    accountLoginMethod,
    createCopilotApiOrganization,
    pickIdentity,
  ]);

  return isLoading ? <Spinner /> : children;
};
