import { useEffect, useState } from "react";
import gql from "graphql-tag";

import { Submit } from "components/Button/Submit";
import { CheckBox } from "components/Form/CheckBox/CheckBox";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { Modal } from "components/Modal/Modal";
import {
  CreateOAuthClient,
  JwkAlgorithmType,
  MeData,
  UpdateOAuthClient,
} from "generated/copilot-api-developer";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

type FormValues = {
  name: string;
  key: string;
};

gql`
  # schema = COPILOT_API_DEVELOPER
  mutation CreateOAuthClient($input: CreateOAuthClientInput!) {
    createOAuthClient(input: $input) {
      organization {
        uuid
      }
    }
  }

  mutation UpdateOAuthClient(
    $oauthClientUuid: UUID!
    $input: UpdateOAuthClientInput!
  ) {
    updateOAuthClient(oauthClientUuid: $oauthClientUuid, input: $input) {
      organization {
        uuid
      }
    }
  }
`;

export type ComposeOAuthClientKeyState =
  | {
      type: "edit";
      clientUUID: string;
      displayName: string;
      jwkSource: MeData["developer"]["organization"]["oauthClients"][0]["externalJwkSource"];
    }
  | {
      type: "create";
    }
  | null;

export const JWTKeyComposer = ({
  showComposer,
  onClose,
  refetch,
}: {
  showComposer: ComposeOAuthClientKeyState;
  onClose: () => void;
  refetch: () => void;
}) => {
  const t = useTranslation();

  const [oAuthClientType, setOAuthClientType] = useState<"url" | "key">(
    showComposer?.type === "edit" &&
      showComposer.jwkSource.__typename === "JwkSourceJwkPublicKey"
      ? "key"
      : "url",
  );

  useEffect(() => {
    if (
      showComposer?.type === "edit" &&
      showComposer.jwkSource.__typename === "JwkSourceJwkPublicKey"
    ) {
      setOAuthClientType("key");
    } else {
      setOAuthClientType("url");
    }
  }, [showComposer]);

  const [createOAuthClient] = useMutation(CreateOAuthClient, {
    onSuccess: () => {
      notifier.success("OAuth client created");
      onClose();
    },
  });

  const [updateOAuthClient] = useMutation(UpdateOAuthClient, {
    onSuccess: () => {
      notifier.success("OAuth client updated");
      onClose();
    },
  });

  return (
    <Modal
      title={t(
        showComposer?.type === "create"
          ? "developers.oauth_clients.key_composer.create"
          : "developers.oauth_clients.key_composer.edit",
      )}
      onHide={onClose}
      show={showComposer !== null}
    >
      <Form<FormValues>
        className="flex-col w-full mt-24 space-y-24"
        initialValues={{
          name: showComposer?.type === "edit" ? showComposer.displayName : "",
          key:
            showComposer?.type === "edit"
              ? showComposer.jwkSource.__typename === "JwkSourceJwksUrl"
                ? showComposer.jwkSource.url
                : showComposer.jwkSource.__typename === "JwkSourceJwkPublicKey"
                ? showComposer.jwkSource.key
                : ""
              : "",
        }}
        onSubmit={({ name, key }) => {
          if (showComposer?.type === "create") {
            createOAuthClient({
              input: {
                displayName: name,
                externalJwkSource: {
                  jwksUrl: oAuthClientType === "url" ? { url: key } : null,
                  jwkPublicKey:
                    oAuthClientType === "key"
                      ? { key, algorithmType: "RSA256" as JwkAlgorithmType }
                      : null,
                },
              },
              // TODO: we should avoid re-fetching, better approach would be updating graphql cache or having a state
            }).then(() => refetch());
          }
          if (showComposer?.type === "edit") {
            updateOAuthClient({
              oauthClientUuid: showComposer.clientUUID,
              input: {
                displayName: name,
                externalJwkSource: {
                  jwksUrl: oAuthClientType === "url" ? { url: key } : null,
                  jwkPublicKey:
                    oAuthClientType === "key"
                      ? { key, algorithmType: "RSA256" as JwkAlgorithmType }
                      : null,
                },
              },
              // TODO: we should avoid re-fetching, better approach would be updating graphql cache or having a state
            }).then(() => refetch());
          }
        }}
      >
        <FormInput
          name="name"
          label={t("developers.oauth_clients.key_composer.name")}
          placeholder={t("developers.oauth_clients.key_composer.name")}
          wrapperClassName="flex-fill"
        />
        <CheckBox
          name="jwk_client_type_url"
          checked={oAuthClientType === "url"}
          onChange={() => {
            if (oAuthClientType === "key") setOAuthClientType("url");
          }}
          label={t("developers.oauth_clients.key_composer.url")}
          iconNameOn="radioOn"
          iconNameOff="radioOff"
        />
        <CheckBox
          name="jwk_client_type_key"
          checked={oAuthClientType === "key"}
          label={t("developers.oauth_clients.key_composer.key")}
          onChange={() => {
            if (oAuthClientType === "url") setOAuthClientType("key");
          }}
          iconNameOn="radioOn"
          iconNameOff="radioOff"
        />
        <FormInput
          name="key"
          label={
            oAuthClientType === "key"
              ? t("developers.oauth_clients.key_composer.insert_key")
              : t("developers.oauth_clients.key_composer.insert_url")
          }
          wrapperClassName="flex-fill"
        />
        <Submit
          className="mt-36"
          label={showComposer?.type === "create" ? "Create" : "Edit"}
        />
      </Form>
    </Modal>
  );
};
