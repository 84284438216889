import { z, ZodSchema } from "zod";

import { NablaRegionKnownValue } from "generated/account";
import { KeysMatching } from "types";

import { AccountJwt, DoctorJwt, ServerJwt } from "./jwt";

/**
 * Kinds of APIs exposed by the backend.
 * Keep in sync with `server/enums/src/main/kotlin/ace/enums/APIKind.kt`.
 */
export type ApiKind =
  | "PROVIDER"
  | "COPILOT_API_DEVELOPER"
  | "ORGANIZATION_USER"
  | "ACCOUNT"
  | "SUPERUSER"
  | "SERVER"; // Used only in the SDK setup guide.

export const API_KIND_BASE_PATH: Record<ApiKind, string> = {
  PROVIDER: "provider",
  COPILOT_API_DEVELOPER: "copilot-api/developer",
  ORGANIZATION_USER: "organizationuser",
  ACCOUNT: "account",
  SUPERUSER: "superuser",
  SERVER: "server",
};

/**
 * Kinds of authentication that the backend expects.
 */
export type EndpointAuthenticationKind =
  | "AUTHENTICATED_AS_DOCTOR"
  | "AUTHENTICATED_AS_COPILOT_API_DEVELOPER"
  | "AUTHENTICATED_AS_SUPERUSER"
  | "AUTHENTICATED_AS_ACCOUNT"
  | "AUTHENTICATED_AS_SERVER"
  | "AUTHENTICATED_WITH_ACCESS_TO_ORGANIZATION_USER_API"
  | "UNAUTHENTICATED_WITH_ORGANIZATION"
  | "UNAUTHENTICATED_ALMIGHTY";

export const AUTHENTICATION_KIND_IS_AUTHENTICATED = {
  AUTHENTICATED_AS_DOCTOR: true,
  AUTHENTICATED_AS_COPILOT_API_DEVELOPER: true,
  AUTHENTICATED_AS_SUPERUSER: true,
  AUTHENTICATED_AS_ACCOUNT: true,
  AUTHENTICATED_AS_SERVER: true,
  AUTHENTICATED_WITH_ACCESS_TO_ORGANIZATION_USER_API: true,
  UNAUTHENTICATED_WITH_ORGANIZATION: false,
  UNAUTHENTICATED_ALMIGHTY: false,
} satisfies Record<EndpointAuthenticationKind, boolean>;

export type AuthenticatedAuthenticationKind = KeysMatching<
  typeof AUTHENTICATION_KIND_IS_AUTHENTICATED,
  true
>;
export type UnauthenticatedAuthenticationKind = KeysMatching<
  typeof AUTHENTICATION_KIND_IS_AUTHENTICATED,
  false
>;

/**
 * Definition of an API endpoint exposed by the backend.
 * See `endpoints.ts` for the list of all endpoints used by the console.
 */
type BaseEndpointDefinition<
  AuthenticationKind extends EndpointAuthenticationKind,
> = {
  apiKind: ApiKind;
  authenticationKind: AuthenticationKind;
  path: string;
};

export type EndpointDefinition<
  AuthenticationKind extends EndpointAuthenticationKind,
> = BaseEndpointDefinition<AuthenticationKind> & {
  requestParamsSchema?: ZodSchema;
  requestDataSchema?: ZodSchema;
  responseSchema: ZodSchema;
};

export type AnyEndpointDefinition =
  EndpointDefinition<EndpointAuthenticationKind>;

/**
 * Definition of a WebSocket endpoint exposed by the backend.
 * See `endpoints.ts` for the list of all socket endpoints used by the console.
 */
export type SocketEndpointDefinition<
  AuthenticationKind extends EndpointAuthenticationKind,
> = BaseEndpointDefinition<AuthenticationKind> & {
  isSocket: true;
};

export type AnySocketEndpointDefinition =
  SocketEndpointDefinition<EndpointAuthenticationKind>;

/**
 * Definition of a GraphQL endpoint exposed by the backend.
 *
 * We don't specify the requestParamsSchema, requestDataSchema or responseSchema
 * because they are standardized for GraphQL endpoints.
 */
export type AuthenticatedGqlEndpointDefinition<
  AuthenticationKind extends AuthenticatedAuthenticationKind,
> = {
  apiKind: ApiKind;
  authenticationKind: AuthenticationKind;
  path: string;
  withSocket?: boolean;
};

export type UnauthenticatedGqlEndpointDefinition<
  AuthenticationKind extends UnauthenticatedAuthenticationKind,
> = {
  apiKind: ApiKind;
  authenticationKind: AuthenticationKind;
  path: string;

  // Right now we only support opening sockets for authenticated schemas.
  //
  // This is because we need to be able to derive a `RequestContext` at socket
  // creation time, and this isn't possible for unauthenticated schemas since
  // their request context is passed as a parameter of the query or mutation.
  withSocket?: undefined;
};

export type AnyGqlEndpointDefinition =
  | AuthenticatedGqlEndpointDefinition<AuthenticatedAuthenticationKind>
  | UnauthenticatedGqlEndpointDefinition<UnauthenticatedAuthenticationKind>;

type JwtGetter<T> = (forceRefresh?: boolean) => Promise<T>;

/**
 * Context for requests to endpoints with a given authentication kind.
 *
 * - AUTHENTICATED_AS_DOCTOR endpoints need either:
 *     * a valid doctor token,
 *     * a valid account token with an explicit doctor identity to use.
 * - AUTHENTICATED_AS_COPILOT_API_DEVELOPER endpoints need either:
 *     * a valid copilot user token,
 *     * a valid account token with an explicit copilot user identity to use.
 * - AUTHENTICATED_WITH_ACCESS_TO_ORGANIZATION_USER_API endpoints can be used
 *   by doctors or copilot users, assuming they `canAccessOrganizationUserApi`.
 * - AUTHENTICATED_AS_SUPERUSER endpoints need either:
 *     * a valid superuser token,
 *     * a valid account token with an explicit superuser identity to use.
 * - AUTHENTICATED_AS_ACCOUNT endpoints need a valid account token.
 * - AUTHENTICATED_AS_SERVER endpoints need a valid server token.
 * - UNAUTHENTICATED_WITH_ORGANIZATION endpoints need an organization ID, and
 *   possibly a sub-organization ID (if different from the default one).
 * - UNAUTHENTICATED_ALMIGHTY endpoints need a cloud region, which can either be
 *   passed explicitly or derived from an account email.
 */
export type RequestContext<K extends EndpointAuthenticationKind> =
  K extends "AUTHENTICATED_AS_DOCTOR"
    ? DoctorRequestContext
    : K extends "AUTHENTICATED_AS_COPILOT_API_DEVELOPER"
    ? CopilotApiUserRequestContext
    : K extends "AUTHENTICATED_WITH_ACCESS_TO_ORGANIZATION_USER_API"
    ? DoctorRequestContext | CopilotApiUserRequestContext
    : K extends "AUTHENTICATED_AS_SUPERUSER"
    ? SuperuserRequestContext
    : K extends "AUTHENTICATED_AS_ACCOUNT"
    ? { accountAccessToken: JwtGetter<AccountJwt> }
    : K extends "AUTHENTICATED_AS_SERVER"
    ? { serverToken: JwtGetter<ServerJwt> }
    : K extends "UNAUTHENTICATED_WITH_ORGANIZATION"
    ? { organizationId: string; subOrganizationId?: string }
    : K extends "UNAUTHENTICATED_ALMIGHTY"
    ?
        | { region: NablaRegionKnownValue | "CLOSEST_REGION" }
        | { regionByAccountEmail: string }
        | { regionByOrganizationId: string }
    : never;

type DoctorRequestContext = (
  | { doctorAccessToken: JwtGetter<DoctorJwt> }
  | {
      accountAccessToken: JwtGetter<AccountJwt>;
      currentIdentityUuid: string;
    }
) & { impersonationUuid?: string };

// Copilot API users can only authenticate via account.
type CopilotApiUserRequestContext = {
  accountAccessToken: JwtGetter<AccountJwt>;
  currentIdentityUuid: string;
};

// Superusers can only authenticate via account.
type SuperuserRequestContext = {
  accountAccessToken: JwtGetter<AccountJwt>;
  currentIdentityUuid: string;
};

/**
 * Payload for requests to a given endpoint.
 */
export type RequestPayload<D extends AnyEndpointDefinition> = {
  clientName?: string;
  headers?: Record<string, string>;
  requestContext: RequestContext<D["authenticationKind"]>;
} & (D["requestParamsSchema"] extends ZodSchema
  ? { params: z.infer<D["requestParamsSchema"]> }
  : { params?: undefined }) &
  (D["requestDataSchema"] extends ZodSchema
    ? { data: z.infer<D["requestDataSchema"]> }
    : { data?: undefined }) &
  (D["requestDataSchema"] extends ZodSchema<FormData>
    ? { onUploadProgress?: (percentage: number) => void }
    : { onUploadProgress?: undefined });
