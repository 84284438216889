import gql from "graphql-tag";

import { useLoggedInAuth } from "auth/AuthContext";
import { isVerifiedLoginMethod } from "auth/utils";
import { Query } from "components/Query/Query";
import {
  AccountIdentities,
  AccountIdentitiesData,
  IdentityFragment,
  NablaProduct,
  SupportedLocale,
} from "generated/account";

import { Maybe } from "../../base-types";
import {
  getGqlLoginMethod,
  getIdentityFromGql,
  getOrganizationFromGql,
  getSubOrganizationFromGql,
  Identity,
  KnownIdentityFragment,
} from "./utils";

gql`
  # schema = ACCOUNT
  fragment AccountSubOrganizationSummary on SubOrganization {
    uuid
    displayName
    organization {
      uuid
      stringId
      displayName
      product
    }
    avatar {
      urlV2 {
        url
        expiresAt
      }
    }
  }

  fragment ProviderSummary on Doctor {
    uuid
    deactivated
    roles
    subOrganization {
      ...AccountSubOrganizationSummary
    }
  }

  fragment CopilotApiDeveloperSummary on CopilotApiDeveloper {
    uuid
    subOrganization {
      ...AccountSubOrganizationSummary
    }
  }

  fragment CopilotAssistantUserSummary on CopilotAssistantUser {
    uuid
    subOrganization {
      ...AccountSubOrganizationSummary
    }
  }

  fragment Identity on Identity {
    uuid
    acceptableLoginMethods

    ... on ProviderIdentity {
      provider {
        ...ProviderSummary
      }
    }

    ... on CopilotApiDeveloperIdentity {
      developer {
        ...CopilotApiDeveloperSummary
      }
    }

    ... on CopilotAssistantUserIdentity {
      user {
        ...CopilotAssistantUserSummary
      }
    }
  }

  fragment Identities on Account {
    uuid
    identities {
      ...Identity
    }
  }

  query AccountIdentities {
    me {
      ...Identities
      email
      unverifiedEmail
      locale
      timezone
      hasPassword
      mfaState {
        ... on SetupMfaState {
          supportedMethods {
            ... on TotpMfaMethod {
              isSetup
            }
            ... on SmsMfaMethod {
              isSetup
              phone
              mfaBySmsAntiAbuseToken
            }
          }
        }
        ... on NotSetupMfaState {
          supportedMethods {
            ... on TotpMfaMethod {
              isSetup
              tentativeQrCode
            }
            ... on SmsMfaMethod {
              isSetup
              tentativePhone
              mfaBySmsAntiAbuseToken
            }
          }
        }
      }
    }
  }
`;

export type IdentitiesQueryIdentities = {
  organization: OrganizationSummary;
  identitiesForOrganization: Identity[];
}[];

export type IdentitiesQueryData = {
  identities: IdentitiesQueryIdentities;
  accountEmail: Maybe<string>;
  accountUnverifiedEmail: string;
  accountHasPassword: boolean;
  accountLocale: SupportedLocale;
  accountTimezone: TimeZone;
  mfaState: AccountIdentitiesData["mfaState"];
};

type OrganizationSummary = {
  uuid: UUID;
  stringId: string;
  displayName: string;
  product: NablaProduct;
};

export type IdentitiesQueryFilter = {
  kind: "CONSOLE_IDENTITIES" | "COPILOT_ASSISTANT_IDENTITIES";
  onlyInSubOrganizationUuid?: string;
};

/**
 * Lets users connected with an account token switch between all the identities
 * associated to their account. Shouldn't be used when there is no logged-in
 * user, or if the user isn't logged in with an account token.
 */
export const IdentitiesQuery = ({
  filter,
  children,
}: {
  filter?: IdentitiesQueryFilter;
  children: (props: IdentitiesQueryData) => JSX.Element;
}) => {
  const auth = useLoggedInAuth();
  if (!auth.canChangeIdentity) return null;

  const currentLoginMethod = getGqlLoginMethod(auth.currentSessionLoginMethod);
  if (!isVerifiedLoginMethod(currentLoginMethod)) return null;

  const identityKindFilter = (
    identity: IdentityFragment,
  ): identity is KnownIdentityFragment => {
    switch (filter?.kind) {
      case "COPILOT_ASSISTANT_IDENTITIES":
        return isCopilotAssistantUserIdentity(identity);
      case "CONSOLE_IDENTITIES":
      case undefined:
        return (
          isCarePlatformIdentity(identity) || isCopilotApiUserIdentity(identity)
        );
    }
  };

  const identitySubOrganizationFilter = (identity: KnownIdentityFragment) => {
    if (filter?.onlyInSubOrganizationUuid) {
      const subOrganization = getSubOrganizationFromGql(identity);
      return subOrganization.uuid === filter.onlyInSubOrganizationUuid;
    }
    return true;
  };

  return (
    <Query query={AccountIdentities}>
      {({
        identities,
        email,
        unverifiedEmail,
        locale,
        timezone,
        hasPassword,
        mfaState,
      }) =>
        children({
          identities: identities
            .filter(identityKindFilter)
            .filter(identitySubOrganizationFilter)
            .map((identity) => ({ identity }))

            .groupByToEntries(
              ({ identity }) => getOrganizationFromGql(identity).uuid,
            )
            .map(([_, identitiesForOrganization]) => ({
              organization: getOrganizationFromGql(
                identitiesForOrganization[0].identity,
              ),
              identitiesForOrganization: identitiesForOrganization
                .mapNotNull((it) => getIdentityFromGql(it, currentLoginMethod))
                .sortAsc((it) => it.uuid),
            }))
            .sortAsc(({ organization }) => organization.displayName),
          accountEmail: email,
          accountUnverifiedEmail: unverifiedEmail,
          accountLocale: locale,
          accountTimezone: timezone,
          accountHasPassword: hasPassword,
          mfaState,
        })
      }
    </Query>
  );
};

const isCopilotApiUserIdentity = (
  identity: IdentityFragment,
): identity is KnownIdentityFragment =>
  identity.__typename === "CopilotApiDeveloperIdentity";

const isCopilotAssistantUserIdentity = (
  identity: IdentityFragment,
): identity is KnownIdentityFragment =>
  identity.__typename === "CopilotAssistantUserIdentity";

const isCarePlatformIdentity = (
  identity: IdentityFragment,
): identity is KnownIdentityFragment =>
  identity.__typename === "ProviderIdentity";
