// @ts-ignore
import gql from "graphql-tag";

// @ts-ignore
import { Maybe, Fragment, Query, Mutation } from "base-types";

export type MutationStatus = "SUCCESS" | "FUTURE_VALUE";
export type MutationStatusKnownValue = "SUCCESS";
export const MutationStatusKnownValues: MutationStatusKnownValue[] = [ "SUCCESS" ];
export type WebhookCallStatus = "ATTEMPTING" | "SUCCEEDED" | "FAILED" | "FUTURE_VALUE";
export type WebhookCallStatusKnownValue = "ATTEMPTING" | "SUCCEEDED" | "FAILED";
export const WebhookCallStatusKnownValues: WebhookCallStatusKnownValue[] = [ "ATTEMPTING","SUCCEEDED","FAILED" ];
export type ApiMethod = "GET" | "PUT" | "POST" | "PATCH" | "DELETE" | "HEAD" | "OPTIONS" | "FUTURE_VALUE";
export type ApiMethodKnownValue = "GET" | "PUT" | "POST" | "PATCH" | "DELETE" | "HEAD" | "OPTIONS";
export const ApiMethodKnownValues: ApiMethodKnownValue[] = [ "GET","PUT","POST","PATCH","DELETE","HEAD","OPTIONS" ];
export type Gender = "MALE" | "FEMALE" | "FUTURE_VALUE";
export type GenderKnownValue = "MALE" | "FEMALE";
export const GenderKnownValues: GenderKnownValue[] = [ "MALE","FEMALE" ];
export type DoctorRole = "NABLA_ADMINISTRATOR" | "NABLA_MEDICAL_STAFF" | "REVIEWER" | "LABELLER" | "FUTURE_VALUE";
export type DoctorRoleKnownValue = "NABLA_ADMINISTRATOR" | "NABLA_MEDICAL_STAFF" | "REVIEWER" | "LABELLER";
export const DoctorRoleKnownValues: DoctorRoleKnownValue[] = [ "NABLA_ADMINISTRATOR","NABLA_MEDICAL_STAFF","REVIEWER","LABELLER" ];



export type CreateAppKeyInput = { name?: Maybe<string>; description?: Maybe<string> }
export type UpdateAppKeyInput = { name?: Maybe<string>; description?: Maybe<string> }
export type TimeRangeInput = { from: ISOString; to: ISOString }
export type RegisterDoctorInput = {
  roles: Array<DoctorRole>;
  gender?: Maybe<Gender>;
  firstName: string;
  lastName: string;
  phone?: Maybe<PhoneNumber>;
  title?: Maybe<string>;
  prefix?: Maybe<string>
}
export type UpdateForStringInput = { value?: Maybe<string> }
export type UpdateSubOrganizationInput = { displayName?: Maybe<UpdateForStringInput> }
export type WebhookEventsFilter = { createdAtAfter?: Maybe<ISOString>; createdAtBefore?: Maybe<ISOString>; type?: Maybe<string> }
export type CreateServerKeyInput = { name?: Maybe<string>; description?: Maybe<string> }
export type UpdateServerKeyInput = { name?: Maybe<string>; description?: Maybe<string> }
export type UpdateOrganizationInput = { apiVersion: string }
export type ApiLogsFilter = { createdAtAfter?: Maybe<ISOString>; createdAtBefore?: Maybe<ISOString>; path?: Maybe<string>; responseCode?: Maybe<Int>; method?: Maybe<ApiMethod> }
export type CreateWebhookInput = { url: string }
export type UpdateWebhookInput = { url: string }

export const possibleTypes: { [key in keyof PossibleTypes]: PossibleTypes[key][] } = {};
export type PossibleTypes = {

};

export type EntityName = 
  | "Organization"
  | "SubOrganization"
  | "ServerKey"
  | "AppKey"
  | "Webhook"
  | "WebhookEvent"
  | "WebhookCall"
  | "WebhookCallFailure"
  | "ApiLog"
export type EntityFieldMap = {
  Organization: "uuid" | "subOrganizationCreationEnabled" | "subOrganizations" | "alertEmails" | "pinnedVersion";
  SubOrganization: "uuid" | "displayName" | "createdAt" | "numberOfProviders" | "numberOfPatients";
  ServerKey: "uuid" | "publicId" | "name" | "description" | "createdAt";
  AppKey: "uuid" | "name" | "description" | "value" | "createdAt";
  Webhook: "uuid" | "url" | "signatureSecret" | "createdAt";
  WebhookEvent: "uuid" | "type" | "createdAt" | "status" | "calls";
  WebhookCall: "uuid" | "createdAt" | "status" | "lastAttemptedAt" | "numFailures" | "nextAttemptAt" | "webhook" | "failures";
  WebhookCallFailure: "uuid" | "exceptionText" | "httpStatusCode" | "httpResponse" | "createdAt";
  ApiLog: "uuid" | "createdAt" | "path" | "method" | "responseCode" | "errorCode" | "errorMessage" | "ipAddress" | "apiKey"
}
export type QueryVariablesMap = { webhookEvent: "uuid"; webhookEvents: "page" | "cursor" | "numberOfItems" | "filters" | "createdAtAfter" | "createdAtBefore" | "type"; apiLog: "uuid"; apiLogs: "page" | "cursor" | "numberOfItems" | "filters" | "createdAtAfter" | "createdAtBefore" | "path" | "responseCode" | "method"; subOrgStatsDashboard: "page" | "from" | "to" }

export type QueryName = 
  | "AllAppKeys"
  | "SpecialSubOrgStatsDashboardQuery"
  | "SubOrganizationsQuery"
  | "GetWebhookEvent"
  | "GetWebhookEvents"
  | "AllServerKeys"
  | "GetAPIVersions"
  | "GetOrganizationAPIVersion"
  | "GetApiLogs"
  | "GetApiLogEndpoints"
  | "GetApiLogCodes"
  | "GetWebhookSettings"
  | "GetWebhooks"

const AppKeyFragmentDocument = gql`
fragment AppKey on AppKey {
  uuid
  name
  description
  value
  createdAt
}

`
export const AppKeyFragmentProps: Fragment<AppKeyFragment> = {
  document: AppKeyFragmentDocument,
  fragmentName: "AppKey",
  entityName: "AppKey",
  __do_not_use_Data: null
}
export type AppKeyFragment = {
  __typename: "AppKey";
  uuid: UUID;
  name: Maybe<string>;
  description: Maybe<string>;
  value: string;
  createdAt: ISOString
}

const SubOrganizationSummaryFragmentDocument = gql`
fragment SubOrganizationSummary on SubOrganization {
  uuid
  displayName
  createdAt
  numberOfProviders
  numberOfPatients
}

`
export const SubOrganizationSummaryFragmentProps: Fragment<SubOrganizationSummaryFragment> = {
  document: SubOrganizationSummaryFragmentDocument,
  fragmentName: "SubOrganizationSummary",
  entityName: "SubOrganization",
  __do_not_use_Data: null
}
export type SubOrganizationSummaryFragment = {
  __typename: "SubOrganization";
  uuid: UUID;
  displayName: Maybe<string>;
  createdAt: ISOString;
  numberOfProviders: Int;
  numberOfPatients: Int
}

const WebhookFragmentDocument = gql`
fragment Webhook on Webhook {
  uuid
  url
  signatureSecret
}

`
export const WebhookFragmentProps: Fragment<WebhookFragment> = {
  document: WebhookFragmentDocument,
  fragmentName: "Webhook",
  entityName: "Webhook",
  __do_not_use_Data: null
}
export type WebhookFragment = { __typename: "Webhook"; uuid: UUID; url: string; signatureSecret: string }

const WebhookCallFragmentDocument = gql`
fragment WebhookCall on WebhookCall {
  uuid
  createdAt
  status
  lastAttemptedAt
  numFailures
  nextAttemptAt
  webhook {
    ...Webhook
  }
  failures {
    uuid
    exceptionText
    httpStatusCode
    httpResponse
    createdAt
  }
}
${WebhookFragmentDocument}
`
export const WebhookCallFragmentProps: Fragment<WebhookCallFragment> = {
  document: WebhookCallFragmentDocument,
  fragmentName: "WebhookCall",
  entityName: "WebhookCall",
  __do_not_use_Data: null
}
export type WebhookCallFragment = {
  __typename: "WebhookCall";
  uuid: UUID;
  createdAt: ISOString;
  status: WebhookCallStatus;
  lastAttemptedAt: ISOString;
  numFailures: Int;
  nextAttemptAt: Maybe<ISOString>;
  webhook: WebhookFragment;
  failures: Array<{
  __typename: "WebhookCallFailure";
  uuid: UUID;
  exceptionText: Maybe<string>;
  httpStatusCode: Maybe<Int>;
  httpResponse: Maybe<string>;
  createdAt: ISOString
}>
}

const WebhookEventFragmentDocument = gql`
fragment WebhookEvent on WebhookEvent {
  uuid
  type
  createdAt
  status
}

`
export const WebhookEventFragmentProps: Fragment<WebhookEventFragment> = {
  document: WebhookEventFragmentDocument,
  fragmentName: "WebhookEvent",
  entityName: "WebhookEvent",
  __do_not_use_Data: null
}
export type WebhookEventFragment = { __typename: "WebhookEvent"; uuid: UUID; type: string; createdAt: ISOString; status: WebhookCallStatus }

const ServerKeyFragmentDocument = gql`
fragment ServerKey on ServerKey {
  uuid
  publicId
  name
  description
  createdAt
}

`
export const ServerKeyFragmentProps: Fragment<ServerKeyFragment> = {
  document: ServerKeyFragmentDocument,
  fragmentName: "ServerKey",
  entityName: "ServerKey",
  __do_not_use_Data: null
}
export type ServerKeyFragment = {
  __typename: "ServerKey";
  uuid: UUID;
  publicId: string;
  name: Maybe<string>;
  description: Maybe<string>;
  createdAt: ISOString
}

const ApiLogFragmentDocument = gql`
fragment ApiLog on ApiLog {
  uuid
  createdAt
  ipAddress
  path
  responseCode
  errorCode
  errorMessage
  apiKey
  method
}

`
export const ApiLogFragmentProps: Fragment<ApiLogFragment> = {
  document: ApiLogFragmentDocument,
  fragmentName: "ApiLog",
  entityName: "ApiLog",
  __do_not_use_Data: null
}
export type ApiLogFragment = {
  __typename: "ApiLog";
  uuid: UUID;
  createdAt: ISOString;
  ipAddress: string;
  path: string;
  responseCode: Int;
  errorCode: Maybe<Int>;
  errorMessage: Maybe<string>;
  apiKey: string;
  method: ApiMethod
}

export type CreateAppKeyData = { __typename: "CreateAppKeyOutput"; appKey: AppKeyFragment }
export type CreateAppKeyVariables = { appKeyInput: CreateAppKeyInput }
export const CreateAppKey: Mutation<CreateAppKeyData, CreateAppKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation CreateAppKey($appKeyInput: CreateAppKeyInput!) {
  createAppKey(appKeyInput: $appKeyInput) {
    appKey {
      ...AppKey
    }
  }
}
${AppKeyFragmentDocument}
`,
  endpointName: "createAppKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateAppKeyData = { __typename: "UpdateAppKeyOutput"; appKey: AppKeyFragment }
export type UpdateAppKeyVariables = { uuid: UUID; appKeyInput: UpdateAppKeyInput }
export const UpdateAppKey: Mutation<UpdateAppKeyData, UpdateAppKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation UpdateAppKey($uuid: UUID!, $appKeyInput: UpdateAppKeyInput!) {
  updateAppKey(uuid: $uuid, appKeyInput: $appKeyInput) {
    appKey {
      ...AppKey
    }
  }
}
${AppKeyFragmentDocument}
`,
  endpointName: "updateAppKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type AllAppKeysData = Array<AppKeyFragment>
export type AllAppKeysVariables = { [key: string]: never }
export const AllAppKeys: Query<AllAppKeysData, AllAppKeysVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query AllAppKeys {
  appKeys {
    ...AppKey
  }
}
${AppKeyFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type DeleteAppKeyData = MutationStatus
export type DeleteAppKeyVariables = { uuid: UUID }
export const DeleteAppKey: Mutation<DeleteAppKeyData, DeleteAppKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation DeleteAppKey($uuid: UUID!) {
  deleteAppKey(uuid: $uuid)
}

`,
  endpointName: "deleteAppKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type SpecialSubOrgStatsDashboardQueryData = { __typename: "SubOrgStatsDashboardOutput"; sections: Array<{ __typename: "SubOrgStatsSection"; title: string; description: string; items: Array<{ __typename: "SubOrgStatsItem"; name: string; value: Int }> }> }
export type SpecialSubOrgStatsDashboardQueryVariables = { page?: Maybe<TimeRangeInput> }
export const SpecialSubOrgStatsDashboardQuery: Query<SpecialSubOrgStatsDashboardQueryData, SpecialSubOrgStatsDashboardQueryVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query SpecialSubOrgStatsDashboardQuery($page: TimeRangeInput) {
  subOrgStatsDashboard(page: $page) {
    sections {
      title
      description
      items {
        name
        value
      }
    }
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type SubOrganizationsQueryData = { __typename: "Organization"; subOrganizationCreationEnabled: boolean; subOrganizations: { __typename: "SubOrganizationsResultsPage"; data: Array<SubOrganizationSummaryFragment>; hasMore: boolean; nextCursor: Maybe<string> } }
export type SubOrganizationsQueryVariables = { cursor?: Maybe<string> }
export const SubOrganizationsQuery: Query<SubOrganizationsQueryData, SubOrganizationsQueryVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query SubOrganizationsQuery($cursor: String) {
  myOrganization {
    subOrganizationCreationEnabled
    subOrganizations(page: {cursor: $cursor}) {
      data {
        ...SubOrganizationSummary
      }
      hasMore
      nextCursor
    }
  }
}
${SubOrganizationSummaryFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type RegisterDoctorInSubOrganizationData = { __typename: "RegisterDoctorOutput"; subOrganization: { __typename: "SubOrganization"; uuid: UUID; numberOfProviders: Int } }
export type RegisterDoctorInSubOrganizationVariables = { subOrganizationUuid: UUID; email: string; payload: RegisterDoctorInput }
export const RegisterDoctorInSubOrganization: Mutation<RegisterDoctorInSubOrganizationData, RegisterDoctorInSubOrganizationVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation RegisterDoctorInSubOrganization($subOrganizationUuid: UUID!, $email: String!, $payload: RegisterDoctorInput!) {
  registerDoctor(
    subOrganizationUuid: $subOrganizationUuid
    email: $email
    payload: $payload
  ) {
    subOrganization {
      uuid
      numberOfProviders
    }
  }
}

`,
  endpointName: "registerDoctor",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type DeleteSubOrganizationData = { __typename: "DeleteSubOrganizationOutput"; deletedSubOrganizationUuid: UUID }
export type DeleteSubOrganizationVariables = { uuid: UUID }
export const DeleteSubOrganization: Mutation<DeleteSubOrganizationData, DeleteSubOrganizationVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation DeleteSubOrganization($uuid: UUID!) {
  deleteSubOrganization(uuid: $uuid) {
    deletedSubOrganizationUuid
  }
}

`,
  endpointName: "deleteSubOrganization",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CreateSubOrganizationData = { __typename: "CreateSubOrganizationOutput"; subOrganization: SubOrganizationSummaryFragment }
export type CreateSubOrganizationVariables = { displayName: string }
export const CreateSubOrganization: Mutation<CreateSubOrganizationData, CreateSubOrganizationVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation CreateSubOrganization($displayName: String!) {
  createSubOrganization(displayName: $displayName) {
    subOrganization {
      ...SubOrganizationSummary
    }
  }
}
${SubOrganizationSummaryFragmentDocument}
`,
  endpointName: "createSubOrganization",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateSubOrganizationData = { __typename: "UpdateSubOrganizationOutput"; subOrganization: SubOrganizationSummaryFragment }
export type UpdateSubOrganizationVariables = { uuid: UUID; input: UpdateSubOrganizationInput }
export const UpdateSubOrganization: Mutation<UpdateSubOrganizationData, UpdateSubOrganizationVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation UpdateSubOrganization($uuid: UUID!, $input: UpdateSubOrganizationInput!) {
  updateSubOrganization(uuid: $uuid, input: $input) {
    subOrganization {
      ...SubOrganizationSummary
    }
  }
}
${SubOrganizationSummaryFragmentDocument}
`,
  endpointName: "updateSubOrganization",
  updateInputsPaths: ["input.displayName"],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type GetWebhookEventData = { __typename: "WebhookEventOutput"; webhookEvent: WebhookEventFragment&{ calls: Array<WebhookCallFragment> } }
export type GetWebhookEventVariables = { uuid: UUID }
export const GetWebhookEvent: Query<GetWebhookEventData, GetWebhookEventVariables, true, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetWebhookEvent($uuid: UUID!) {
  webhookEvent(uuid: $uuid) {
    webhookEvent {
      ...WebhookEvent
      calls {
        ...WebhookCall
      }
    }
  }
}
${WebhookEventFragmentDocument}
${WebhookCallFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type RetryWebhookCallData = { __typename: "RetryWebhookCallOutput"; webhookCall: WebhookCallFragment }
export type RetryWebhookCallVariables = { uuid: UUID }
export const RetryWebhookCall: Mutation<RetryWebhookCallData, RetryWebhookCallVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation RetryWebhookCall($uuid: UUID!) {
  retryWebhookCall(webhookCallUuid: $uuid) {
    webhookCall {
      ...WebhookCall
    }
  }
}
${WebhookCallFragmentDocument}
`,
  endpointName: "retryWebhookCall",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type GetWebhookEventsData = { __typename: "WebhookEventsOutput"; nextCursor: Maybe<string>; hasMore: boolean; data: Array<WebhookEventFragment> }
export type GetWebhookEventsVariables = { filters?: Maybe<WebhookEventsFilter>; cursor?: Maybe<string> }
export const GetWebhookEvents: Query<GetWebhookEventsData, GetWebhookEventsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetWebhookEvents($filters: WebhookEventsFilter, $cursor: String) {
  webhookEvents(page: {numberOfItems: 20, cursor: $cursor}, filters: $filters) {
    nextCursor
    hasMore
    data {
      ...WebhookEvent
    }
  }
}
${WebhookEventFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type CreateServerKeyData = { __typename: "CreateServerKeyOutput"; serverKey: ServerKeyFragment; bearerToken: string }
export type CreateServerKeyVariables = { serverKeyInput: CreateServerKeyInput }
export const CreateServerKey: Mutation<CreateServerKeyData, CreateServerKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation CreateServerKey($serverKeyInput: CreateServerKeyInput!) {
  createServerKey(serverKeyInput: $serverKeyInput) {
    serverKey {
      ...ServerKey
    }
    bearerToken
  }
}
${ServerKeyFragmentDocument}
`,
  endpointName: "createServerKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateServerKeyData = { __typename: "UpdateServerKeyOutput"; serverKey: ServerKeyFragment }
export type UpdateServerKeyVariables = { uuid: UUID; serverKeyInput: UpdateServerKeyInput }
export const UpdateServerKey: Mutation<UpdateServerKeyData, UpdateServerKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation UpdateServerKey($uuid: UUID!, $serverKeyInput: UpdateServerKeyInput!) {
  updateServerKey(uuid: $uuid, serverKeyInput: $serverKeyInput) {
    serverKey {
      ...ServerKey
    }
  }
}
${ServerKeyFragmentDocument}
`,
  endpointName: "updateServerKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type AllServerKeysData = Array<ServerKeyFragment>
export type AllServerKeysVariables = { [key: string]: never }
export const AllServerKeys: Query<AllServerKeysData, AllServerKeysVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query AllServerKeys {
  serverKeys {
    ...ServerKey
  }
}
${ServerKeyFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type DeleteServerKeyData = MutationStatus
export type DeleteServerKeyVariables = { uuid: UUID }
export const DeleteServerKey: Mutation<DeleteServerKeyData, DeleteServerKeyVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation DeleteServerKey($uuid: UUID!) {
  deleteServerKey(uuid: $uuid)
}

`,
  endpointName: "deleteServerKey",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type GetAPIVersionsData = { __typename: "APIVersionsOutput"; versions: Array<string> }
export type GetAPIVersionsVariables = { [key: string]: never }
export const GetAPIVersions: Query<GetAPIVersionsData, GetAPIVersionsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetAPIVersions {
  apiVersions {
    versions
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetOrganizationAPIVersionData = { __typename: "Organization"; uuid: UUID; pinnedVersion: string }
export type GetOrganizationAPIVersionVariables = { [key: string]: never }
export const GetOrganizationAPIVersion: Query<GetOrganizationAPIVersionData, GetOrganizationAPIVersionVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetOrganizationAPIVersion {
  myOrganization {
    uuid
    pinnedVersion
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type UpdateOrganizationAPIVersionData = { __typename: "UpdateOrganizationOutput"; organization: { __typename: "Organization"; uuid: UUID; pinnedVersion: string } }
export type UpdateOrganizationAPIVersionVariables = { input: UpdateOrganizationInput }
export const UpdateOrganizationAPIVersion: Mutation<UpdateOrganizationAPIVersionData, UpdateOrganizationAPIVersionVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation UpdateOrganizationAPIVersion($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    organization {
      uuid
      pinnedVersion
    }
  }
}

`,
  endpointName: "updateOrganization",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type GetApiLogsData = { __typename: "ApiLogsOutput"; nextCursor: Maybe<string>; hasMore: boolean; data: Array<ApiLogFragment> }
export type GetApiLogsVariables = { filters?: Maybe<ApiLogsFilter>; cursor?: Maybe<string> }
export const GetApiLogs: Query<GetApiLogsData, GetApiLogsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetApiLogs($filters: ApiLogsFilter, $cursor: String) {
  apiLogs(page: {numberOfItems: 20, cursor: $cursor}, filters: $filters) {
    nextCursor
    hasMore
    data {
      ...ApiLog
    }
  }
}
${ApiLogFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetApiLogEndpointsData = { __typename: "ApiLogEndpointsOutput"; paths: Array<string> }
export type GetApiLogEndpointsVariables = { [key: string]: never }
export const GetApiLogEndpoints: Query<GetApiLogEndpointsData, GetApiLogEndpointsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetApiLogEndpoints {
  apiLogEndpoints {
    paths
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetApiLogCodesData = { __typename: "ApiLogCodesOutput"; codes: Array<Int> }
export type GetApiLogCodesVariables = { [key: string]: never }
export const GetApiLogCodes: Query<GetApiLogCodesData, GetApiLogCodesVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetApiLogCodes {
  apiLogCodes {
    codes
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetWebhookSettingsData = { __typename: "Organization"; alertEmails: Array<EmailAddress> }
export type GetWebhookSettingsVariables = { [key: string]: never }
export const GetWebhookSettings: Query<GetWebhookSettingsData, GetWebhookSettingsVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetWebhookSettings {
  myOrganization {
    alertEmails
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type GetWebhooksData = Array<WebhookFragment>
export type GetWebhooksVariables = { [key: string]: never }
export const GetWebhooks: Query<GetWebhooksData, GetWebhooksVariables, false, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
query GetWebhooks {
  webhooks {
    ...Webhook
  }
}
${WebhookFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type DeleteWebhookData = MutationStatus
export type DeleteWebhookVariables = { uuid: UUID }
export const DeleteWebhook: Mutation<DeleteWebhookData, DeleteWebhookVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation DeleteWebhook($uuid: UUID!) {
  deleteWebhook(uuid: $uuid)
}

`,
  endpointName: "deleteWebhook",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type SendFakeWebhookEventData = { __typename: "SendFakeWebhookEventOutput"; webhookCall: WebhookCallFragment }
export type SendFakeWebhookEventVariables = { webhookUuid: UUID; eventType: string; eventDataJson: string }
export const SendFakeWebhookEvent: Mutation<SendFakeWebhookEventData, SendFakeWebhookEventVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation SendFakeWebhookEvent($webhookUuid: UUID!, $eventType: String!, $eventDataJson: String!) {
  sendFakeWebhookEvent(
    webhookUuid: $webhookUuid
    eventType: $eventType
    eventDataJson: $eventDataJson
  ) {
    webhookCall {
      ...WebhookCall
    }
  }
}
${WebhookCallFragmentDocument}
`,
  endpointName: "sendFakeWebhookEvent",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CreateWebhookData = { __typename: "CreateWebhookOutput"; webhook: WebhookFragment }
export type CreateWebhookVariables = { input: CreateWebhookInput }
export const CreateWebhook: Mutation<CreateWebhookData, CreateWebhookVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation CreateWebhook($input: CreateWebhookInput!) {
  createWebhook(input: $input) {
    webhook {
      ...Webhook
    }
  }
}
${WebhookFragmentDocument}
`,
  endpointName: "createWebhook",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateWebhookData = { __typename: "UpdateWebhookOutput"; webhook: WebhookFragment }
export type UpdateWebhookVariables = { uuid: UUID; input: UpdateWebhookInput }
export const UpdateWebhook: Mutation<UpdateWebhookData, UpdateWebhookVariables, "ORGANIZATION_USER"> = {
  schemaType: "ORGANIZATION_USER",
  document: gql`
mutation UpdateWebhook($uuid: UUID!, $input: UpdateWebhookInput!) {
  updateWebhook(uuid: $uuid, input: $input) {
    webhook {
      ...Webhook
    }
  }
}
${WebhookFragmentDocument}
`,
  endpointName: "updateWebhook",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}