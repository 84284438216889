import { useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { Table } from "components/Table/Table";
import { DeleteOAuthClient, Me } from "generated/copilot-api-developer";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { copyToClipBoard } from "utils";
import { notifier } from "utils/notifier";

import { APIVersionSelector } from "../../../shared/Developers/ServerKeys/APIVersionSelector";
import {
  ComposeOAuthClientKeyState,
  JWTKeyComposer,
} from "../../../shared/Developers/ServerKeys/JWTKeyComposer";

gql`
  # schema = COPILOT_API_DEVELOPER
  query Me {
    me {
      developer {
        uuid
        organization {
          uuid
          oauthClients {
            uuid
            displayName
            createdAt
            lastUsedAt
            externalJwkSource {
              ... on JwkSourceJwksUrl {
                url
              }
              ... on JwkSourceJwkPublicKey {
                key
                algorithmType
              }
            }
          }
        }
      }
    }
  }

  mutation DeleteOAuthClient($oauthClientUuid: UUID!) {
    deleteOAuthClient(oauthClientUuid: $oauthClientUuid) {
      organization {
        uuid
      }
    }
  }
`;

export const OAuthClientKeys = () => {
  const t = useTranslation();
  const [addingOAuthClientKey, setAddingOAuthClientKey] =
    useState<ComposeOAuthClientKeyState>(null);
  const isDesktop = useIsDesktop();

  const [deleteOAuthClient] = useMutation(DeleteOAuthClient, {
    onSuccess: () => {
      notifier.success("OAuth client deleted");
    },
  });

  const { data, refetch } = useQuery(Me);
  const oauthClients = data?.developer.organization.oauthClients ?? [];

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <div className="flex items-center">
          <h1 className="text-primary-dark text-24 font-bold">
            {t("developers.oauth_clients")}
          </h1>

          <Button
            label={
              isDesktop
                ? t("developers.oauth_clients.add_key.long")
                : t("developers.oauth_clients.add_key.short")
            }
            onClick={() => setAddingOAuthClientKey({ type: "create" })}
            className="ml-auto"
          />
        </div>
      </div>
      <JWTKeyComposer
        onClose={() => {
          setAddingOAuthClientKey(null);
        }}
        showComposer={addingOAuthClientKey}
        refetch={refetch}
      />
      <APIVersionSelector appFlavor="COPILOT_API" />
      <Table
        elements={oauthClients}
        fieldHeaders={[
          "Display name",
          "Created at",
          "Last used at",
          "JWK source",
        ]}
        fields={(client) => [
          client.displayName,
          client.createdAt.format("date"),
          client.lastUsedAt?.format("date"),
          <div className="flex flex-col" key={client.uuid}>
            <div className="pb-2">
              <div
                className={classNames("inline-block rounded px-4", {
                  "bg-orange":
                    client.externalJwkSource.__typename ===
                    "JwkSourceJwkPublicKey",
                  "bg-success":
                    client.externalJwkSource.__typename === "JwkSourceJwksUrl",
                })}
              >
                {client.externalJwkSource.__typename === "JwkSourceJwksUrl"
                  ? "JWKS URL"
                  : "JWK Public Key"}
              </div>
            </div>
            <div>
              {client.externalJwkSource.__typename === "JwkSourceJwksUrl"
                ? client.externalJwkSource.url.toString()
                : client.externalJwkSource.__typename ===
                  "JwkSourceJwkPublicKey"
                ? `${String(client.externalJwkSource.algorithmType)}: ${String(
                    client.externalJwkSource.key.toString().slice(0, 30),
                  )}...`
                : "N/A"}
            </div>
          </div>,
        ]}
        menuItems={(client) => [
          {
            icon: "clipboard",
            text: "Copy OAuth client ID",
            onClick: (close: () => void) => {
              close();
              void copyToClipBoard(client.uuid, "OAuth client ID copied");
            },
            bottomSeparator: true,
          },
          {
            icon: "edit",
            text: "Edit",
            onClick: (close: () => void) => {
              close();
              setAddingOAuthClientKey({
                type: "edit",
                clientUUID: client.uuid,
                displayName: client.displayName,
                jwkSource: client.externalJwkSource,
              });
            },
          },
          {
            icon: "trash",
            text: "Delete",
            onClick: () => {
              deleteOAuthClient({ oauthClientUuid: client.uuid }).then(() =>
                // TODO: we should avoid re-fetching, better approach would be updating graphql cache or having a state
                refetch(),
              );
            },
          },
        ]}
      />
    </Background>
  );
};
